import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet de color blanc que es caracteritza per canviar tot ell de color quan es toca, tornant-se groc i per l’olor a gas que fa. El capell de 4 a 15 cm de diàmetre és globós al principi per passar després a pla. Les làmines són de color rosa passant posteriorment a color marró xocolata, fines, apretades i lliures. El peu, blanc, és cilíndric, uniforme, ondulat i de 8 a 20 cm de longitud. L’anell és ample, persistent i presenta esquames a la part inferior. Les espores són de color marró violaci en massa, ovoides, de 5-6,5 x 3,5-4 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      